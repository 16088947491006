import React from 'react'
import { Layout, Seo } from '../components'
import {
    Intro,
    HomeSectionWithBullets,
    // Doctor,
    HowItWorks,
    HomeSectionGreySegments,
    DoctorsRow,
    Download,
} from '../sections'
import { injectIntl, useIntl } from 'gatsby-plugin-intl'

const IndexPage = () => {
    const intl = useIntl()
    const t = x => {
        return intl.formatMessage({ id: x })
    }
    return (
        <Layout>   
            <Seo description={t('home_seo_description')} />
            <Intro />
            <HomeSectionWithBullets />
            {/* temporary hidden */}
            {/* <Doctor /> */}
            <HowItWorks />
            <HomeSectionGreySegments />
            <DoctorsRow />
            <Download />
        </Layout>
    )
}

export default injectIntl(IndexPage)
